body,
div,
h1,
h2,
h3,
span,
p {
  font-family: Verdana, Arial, Helvetica, sans-serif;
}

/* fullscreen */
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0px;
  overflow: hidden; /* disable scrollbars */
  font-size: 10pt;
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); /* remove highlight on tab for iOS/Android */
}

/* fix for scroll bars on webkit & >=Mac OS X Lion */
::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.5);
  width: 0.75em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
}
